import SongPicker from './SongPicker';
import ContactDetails from './ContactDetails';

import { Routes, Route } from "react-router-dom";


function App() {
    return (
        <div>
        <Routes>
            <Route path="/" element={<SongPicker />} />
            <Route path="/contact" element={<ContactDetails />} />
        </Routes>
        </div>
    );
}

export default App;
