import reactDom from 'react-dom';
import NameField from './components/NameField';


function ConfirmModal({ modalOpen, closeModal, artist, title, submitSong, setDedication, setUserName, userName }) {  //
    var clicked = false;

    if (!modalOpen) {
        return null;
    } else {

    }

    function DisableRequestButton() {
  
      document.getElementById('request-text').innerHTML = 'Thank You for your Request!';
      document.getElementById('request-button').className = 'py-2 px-4 flex justify-center flex-row bg-green-900 text-white rounded-lg cursor-pointer hover:bg-green-900 duration-200 w-36';
      
      if (!clicked) {
        setTimeout(function(){ submitSong(); }, 2500);
        clicked = true;
      }

    }
  
    return reactDom.createPortal(    //Implementing portal
        <div className="bg-gray-500 bg-opacity-70 absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center">
          <div className="bg-white px-8 py-2 rounded-2xl">
            <div className="text-3xl font-bold mx-auto text-center">
              <h3 className="">Confirm Your Selection</h3>
            </div>
            <div className="mt-4 text-2xl py-2 px-2 border border-black rounded-xl text-center text-slate-700">
              {artist} - {title}
            </div>
            <div><NameField setUserNameFunction={setUserName} name={userName}/></div>
            <div className="mt-4 text-2xl py-2 px-2  rounded-xl border border-black">
              <input
                className=""
                type="text"
                name=""
                id=""
                placeholder="Optional Dedication..."
                onInput={e => setDedication(e.target.value)}
              />
            </div>

            <div className="mt-12 flex justify-around mb-2">
              <button 
                className="py-2 px-4 flex justify-center flex-row bg-green-500 rounded-lg cursor-pointer hover:bg-green-600 duration-200 w-36"
                onClick={DisableRequestButton} id='request-button'>
                <h3 className="text-xl font-normal" id='request-text'>Request Song</h3>
              </button>
              <button
                className="py-2 px-4 flex justify-center flex-row border-2 border-red-600 rounded-lg cursor-pointer hover:bg-red-400 duration-200 w-36 "
                onClick={closeModal}>
                <h3 className="text-xl font-normal">Cancel Request</h3>
              </button>
            </div>
          </div>
        </div>,
    
        document.getElementById("modal")    //Assign the root div to render to
      );
};

export default ConfirmModal;

