import React from "react";

const Song = (props) => {
    // console.log(props)
    const enqueueSong = () => {
        props.setSongSelected(true);
        props.setArtist(props.artist);
        props.setTitle(props.title);
        props.setID(props.id);
    };

    var inner_html = "";
    if (props.sortKey === "artist") {
        inner_html = <div>{props.artist} &mdash; {props.title}</div>;
    } else {
        inner_html = <div>{props.title} &mdash; {props.artist}</div>;
    }

    return (
        <div key={props.index} className="py-2 px-2 text-md md:text-lg font-bold text-left" onClick={enqueueSong}>
            {inner_html}
        </div>
        
    )
};

export default Song;