import React from "react";
import FlashMessage from "react-flash-message";


const NameField = (props) => {
    const [name, setName] = React.useState(props.name);
    const [showFlash, setShowFlash] = React.useState(false);

    const inputRef = React.useRef(null);
 
    React.useEffect(() => {
        inputRef.current.value = props.name;
    }, [props.name]);

    const nameValue = (e) => {
        setName(e.target.value);
    };

    
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            doSetName();
        }
    };

    const doSetName = () => {
        if (name === "") {
            setShowFlash(true);
        } else {
            props.setUserNameFunction(name); // pass the name up to the parent component
        }
    };

    return (
     
            <div className="mt-4 text-2xl py-2 px-2  rounded-xl border border-black">
                <input ref={inputRef} className="text-black px-2 text-2xl" 
                type="text" placeholder="Your Name" onChange={nameValue} onKeyDown={handleKeyDown} onBlur={doSetName} autoFocus={true}/>
                {showFlash && (
                    <FlashMessage duration={5000}>
                        <h3 className="text-red-600">Please enter your name</h3>
                    </FlashMessage>
                )}
            </div>           
           
        
    );
}

export default NameField;
