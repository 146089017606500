import React from "react";

const SearchBox = (props) => {
    const inputRef = React.useRef(null);
    const [searchTerm, setSearchTerm] = React.useState("");

    const searchTermValue = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value === "") {
            clearSearch();
        }
    };
    const doSearch = () => {
        // when button pressed
        //console.log(searchTerm)
        props.setResetSearchFunction(false);
        props.setSearchTermFunction(searchTerm); // pass the search term to the parent component
    };
    const clearSearch = () => {
        // when button pressed
        setSearchTerm("");
        // inputRef.current.value = "";
        props.setResetSearchFunction(true); // pass the reset to the parent component
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            doSearch();
        }
    };

    return (
        <div className="grid grid-cols-3 mr-4">
            <input ref={inputRef} 
            className="col-span-2 rounded-lg border-tonyblue-900 border text-black px-2 ml-4 mr-3 text-md md:text-xl" 
                type="search" placeholder="Search" onChange={searchTermValue} onKeyDown={handleKeyDown}/>
                <button className="border-tonyblue-100 border bg-tonyblue-100 text-black" onClick={doSearch}>Search</button>

        </div>
    );
}

export default SearchBox