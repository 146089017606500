import { Link } from "react-router-dom";

const ContactDetails = () => {
  return (

    <div className="App">
      <div className="bg-tonyblue-900 text-white flex flex-row justify-between w-screen">
        <div className="text-2xl"><h1 className='px-6'>Contact Details</h1></div>
        <div className="text-2xl pr-2 text-slate-300 underline"><Link to="/">⬅️</Link></div>

      </div>

      <div>
        <div className="w-screen grid grid-cols-1 place-items-start">
          <div className='text-left'>

            <div className="text-2xl ml-6">
              <div className="py-2">Tony Hodder</div>
              <div className="text-blue-800 py-2"> 📧 <a href="mailto:livejukeboxtony@gmail.com">livejukeboxtony@gmail.com</a></div>
              <div className="text-blue-800 py-2">☎️ <a href="tel:+447810 553350">07810 553 350</a></div>
              <div className="py-2">
                <span className="text-black">Facebook: </span>
                <span className="text-blue-800 py-2">
                  <a href="https://www.facebook.com/fattonysband">@fattonysband</a>
                </span>
              </div>
              <div className="py-2">
                <span className="text-black">Instagram: </span>
                <span className="text-blue-800 py-2">
                  <a href="https://www.instagram.com/live.jukebox">@live.jukebox</a>
                </span>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactDetails;
