// import react from "react";
import React from "react";
import Song from "./Song";

const SongList = (props) => {
    return (
        <div className="place-content-center">
            <div className="content-start mx-auto">
                <div className="divide-y overflow-auto">
                    {props.songData && props.songData.map((item, index) => (
                        <Song 
                        key={index} 
                        title={item.Title} 
                        artist={item.Artist} 
                        id={item.ID} 
                        sortKey={props.sortedBy} 
                        setArtist={props.setArtist}
                        setTitle={props.setTitle}
                        setSongSelected={props.setSongSelected}
                        setID={props.setID}
                        />
                    ))}
                </div>
                    
            </div>
        </div>
    );
}

export default SongList;
